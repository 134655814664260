import { PinPoints } from './pinpoints';

export class Disappeared {
    pet:any = null;
    address:object = null;
    date:string = null;
    user:string = null;
    status:string = null;
    description:string = null;
    docRef:string = null;
    constructor(model:Disappeared = null){
        if(model!=null){
            this.extractModel(model);
        }
    }
    extractModel(model:Disappeared){
        this.pet = model.pet;
        this.address = model.address;
        this.date = model.date;
    }
    buildPinPointData(){
        let data:any = this.pet;
        data.address = this.address;
        return data;
    }
    format(){
        let pinpoint = new PinPoints();
        pinpoint.type = 'disappeared';
        pinpoint.status = 'pending';
        if(this.description!=null && this.description != ''){
            this.pet.description = this.description;
        }
        if(this.user!=null && this.user != ''){
            pinpoint.user = this.user;
        }
        pinpoint.date = this.date;
        pinpoint.data = this.buildPinPointData();
        pinpoint.docRef = this.docRef;
        return pinpoint.format();
    }
}